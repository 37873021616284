<template>
 <div>
  <div class="flex flex-wrap  justify-center	 ">
    <div class="bg-white p-10 w-5/12 sm:w-1/4	m-5  flex flex-col	 justify-between rounded-lg shadow-sm" v-for="service in services" :key="service.i18n" >
      <img :src="service.icon" alt="Service Icon" class="w-16 h-16 mb-4 mx-auto"/>
      <span class="text-xl my-3 text-center text-p">{{ $t(service.i18n) }}</span>
      <span class="text-sm my-3 text-center text-p">{{ $t(service.details) }}</span>
      <vs-button color="#7732c5" type="border" icon="link">
        {{ $t('pages.eventfulServices.buttonToGo') }}
      </vs-button>
    </div>
  </div>  
  </div>
  </template>

  <script>
  
  export default {
    data() {
      return {
        services:[
          {
            icon: require('@/assets/icons/tb-brand-whatsapp.svg'),
            url:'https://wa.me/966504849043?text=السلام عليكم، انا ${currentUser.full_name} من ${venue.arabic_name} واحتاج للمساعدة`',
            target: '_blank',
            slug: 'external',
            i18n: 'pages.eventfulServices.contactUs',
            details:'pages.eventfulServices.contactUsDetails'
          },
          {
            icon: require('@/assets/icons/tb-camera.svg'),
            url:'https://wa.me/966504849043?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20التصوير%20ثلاثي%20الأبعاد',
            target: '_blank',
            slug: 'external',
            i18n: 'pages.eventfulServices.thirdDimensionPhotography',
            details:'pages.eventfulServices.thirdDimensionPhotographyDetails'
          },
          {
            icon: require('@/assets/icons/tb-social.svg'),
            url:'https://wa.me/966504849043?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20إدارة%20حسابات%20السوشل%20ميديا',
            target: '_blank',
            slug: 'external',
            i18n: 'pages.eventfulServices.manageSocialMedia',
            details:'pages.eventfulServices.manageSocialMediaDetails',

          },
          {
            icon: require('@/assets/icons/web-dev.svg'),
            url:'https://wa.me/966504849043?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20موقع%20الكتروني%20خاص',
            target: '_blank',
            slug: 'external',
            i18n: 'pages.eventfulServices.specialWebsite',
            details:'pages.eventfulServices.specialWebsiteDetails',
          },
          {
            icon: require('@/assets/icons/tb-apps.svg'),
            url:'https://wa.me/966504849043?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20تطبيق%20خاص',
            target: '_blank',
            slug: 'external',
            i18n: 'pages.eventfulServices.specialApp',
            details:'pages.eventfulServices.specialAppDetails',
          },

        ]
    
      };
   },
    methods: {
      go_to_link(url) {
      window.open(url, '_blank');
      },
    },

    created() {
      this.$store.dispatch('loader/loaderOff')
    },

  };
  </script>

  <style>
    .text-p{
      color: #7732c5;
    }
  
  </style>